<template>
	<div>
		<div class="header">
			<h1 class="heading-3-b" v-html="title">{{ title }}</h1>
			<button class="icon" @click="handleClose">
				<i class="ic-24 ic-close"></i>
			</button>
		</div>

		<div class="contents-wrap">
			<p v-if="hasHtml" v-html="contents">{{ contents }}</p>
			<p v-else class="text-center">{{ contents }}</p>
		</div>

		<div class="btn-area group-btn">
			<button
				v-for="(button, index) in buttons.slice().reverse()"
				:key="index"
				:class="getButtonClass(index)"
				@click="
					() => {
						button.callback();
						close();
					}
				"
			>
				{{ button.text }}
			</button>
		</div>
	</div>
</template>
<script>
export default {
	name: 'CommonAlert',
	props: {
		title: String,
		contents: String,
		buttons: Array,
		heightForScroll: String,
		useHtml: Boolean,
	},
	data() {
		return {
			isHtml: this.useHtml,
		};
	},
	computed: {
		cancelButton() {
			return this.buttons[this.buttons.length - 1];
		},
		hasHtml() {
			const regex = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
			return regex.test(this.contents);
		},
	},
	methods: {
		close() {
			this.$emit('close');
		},
		handleClose() {
			if (this.buttons.length > 1) {
				this.cancelButton.callback();
			}
			this.close();
		},
		getButtonClass(index) {
			const className = this.buttons[index]?.className;
			const hasClass = !!className;
			if (hasClass) return className;
			return this.buttons.length === index + 1 ? 'solid' : 'line';
		},
	},
};
</script>
<style scoped>
@import '../../assets/css/common.css';
@import '../../assets/css/login.css';
</style>
