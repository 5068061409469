import CommonAlert from '@/components/common/CommonAlert.vue';
import { MEDIA_MODAL_STYLE, SIGNUP_MODAL_STYLE } from '@/utils/constants.js';

const DEFAULT_BUTTONS = [
	{
		text: '확인',
	},
	{
		text: '취소',
	},
];

const DEFAULT_BUTTON = { text: '' };

/**
 * name: String. 필수 아님. alert id. 중첩 얼럿을 사용하는 경우 name 을 넣어줘야 모든 alert 이 닫히는걸 방지할 수 있음.
 * title: String. 필수 아님.
 * contents: String. 필수 아님. html 을 넣으면 html 또는 text.
 * buttons: Array[Button]. 필수 아님. 넣지 않으면 "확인" 버튼 하나만 노출.
 * button: Object. 필수 아님.
 * {
 *     text. 필수 아님. 기본값은 확인, 취소. 첫번째 버튼은 확인. 두번째 버튼은 취소.
 *     callback. 필수 아님. 가본값은 this.$modal.hide.
 *     className. 필수 아님. 기본값은 solid, line. 첫번째 버튼만 solid.
 * }
 */
export default {
	name: 'mixinsAlert',
	methods: {
		// alert(modalName, title, text, acceptButtonText, handleAccept, rejectButtonText, handleReject) {
		alert(option) {
			let alertOption = option;
			if (typeof option === 'string') alertOption = { contents: option };
			const id = Math.random().toString(16).slice(2);
			const name = alertOption.name || id;
			if (!alertOption.buttons || !alertOption.buttons.length) alertOption.buttons = [DEFAULT_BUTTONS[0]];
			alertOption.buttons.forEach((button, index) => {
				const template = DEFAULT_BUTTONS[index] || DEFAULT_BUTTON;
				button.text = button.text || template.text;
				button.callback = button.callback || (() => this.$modal.hide(name));
			});
			let style = SIGNUP_MODAL_STYLE;
			if (alertOption.styleType == 'medium') {
				style = MEDIA_MODAL_STYLE;
			}
			this.$modal.show(
				CommonAlert,
				{
					title: alertOption.title || '',
					contents: alertOption.contents || '',
					buttons: alertOption.buttons || [],
				},
				Object.assign({}, style, { name, clickToClose: false }),
			);
			return id;
		},
	},
};
